
import styled from 'styled-components'
import { Link } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md'


export const HeroBtn = styled(Link)`
  border-radius: 5px;
  background: ${({ primary }: any) => (primary ? 'var(--red)' : 'var(--gray)')};
  white-space: nowrap;
  padding: ${({ big }: any ) => (big ? '14px 48px' : '12px 30px')};
  color: ${({ dark }: any ) => (dark ? '#ffffff' : '#ffffff')};
  font-size: ${({ fontBig }: any ) => (fontBig ? '20px' : '16px')};
  font-family: var(--font-family-montserrat);
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }: any ) => (primary ? 'var(--yellow)' : 'var(--gray)')};
  }
`
export const Button = styled(Link)`
  border-radius: 5px;
  background: ${({ primary }: any) => (primary ? 'var(--red)' : 'var(--gray)')};
  white-space: nowrap;
  padding: ${({ big }: any ) => (big ? '14px 48px' : '12px 30px')};
  color: ${({ dark }: any ) => (dark ? '#ffffff' : '#ffffff')};
  font-size: ${({ fontBig }: any ) => (fontBig ? '20px' : '16px')};
  font-family: var(--font-family-montserrat);
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }: any ) => (primary ? 'var(--green)' : 'var(--gray)')};
  }
`

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`

export const CtaBtn = styled(LinkR)`
  border-radius: 5px;
  background: ${({ primary }: any) => (primary ? 'var(--red)' : 'var(--gray)')};
  white-space: nowrap;
  padding: ${({ big }: any ) => (big ? '14px 48px' : '12px 30px')};
  color: ${({ dark }: any ) => (dark ? '#ffffff' : '#ffffff')};
  font-size: ${({ fontBig }: any ) => (fontBig ? '20px' : '16px')};
  font-family: var(--font-family-montserrat);
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }: any ) => (primary ? 'var(--green)' : 'var(--gray)')};
  }
`
export const MailToBtnLink = styled.a`
  border-radius: 5px;
  padding: 14px 48px;
  background: var(--green);
  color: #fff;
  font-family: var(--font-family-montserrat);
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  white-space: nowrap;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  outline: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: var(--red);
    
  }
`