// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'
import Image from '../../img/farmland.jpeg'
import Video from '../../videos/video2.mp4'
import { VideoBg } from '../Heros/HeroDev'
import { MailToBtnLink } from '../Elements.Button'
import { HeroBtnWrapper } from '../Heros/Elements.Hero'

const CtaContainer = styled.div`
  background: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  min-height: 100vh;
  /* height: 800px; */
  position: relative;
  z-index: 1;

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(65,64,65,0.1), rgba(56,178,74,0.3));
    width: 100%;
    height: 100%;
    position: absolute;
    /* background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(65,64,65,0.8)),
        to(rgba(65,64,65,0.8))
      ),
      -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255,199,30,0.8)),
        to(transparent));
    background: linear-gradient(
        180deg,
        rgba(65,64,65,0.8) 10%,
        rgba(255,199,30,0.5) 100%
      ),
      linear-gradient(180deg, rgba(65,64,65,0.2) 0%, transparent 100%); */
    z-index: 2;
  }
`
export const CtaBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;  
`
export const CtaImgBg = styled.img`
  width: 100%;
  /* height: 100%; */
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  /* background: var(--gray);   */
`

export const CtaContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CtaTitleH2 = styled.h2`
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-weight: 500;
  text-transform: capitalize;
  /* letter-spacing: 0.125rem; */
  font-size: 32px;
  text-align: center;
  span {
    font-weight: 800;
    font-size: 42px;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
    font-family: var(--font-family-azo_sans-bold);
  }
`

export const CtaMessage = styled.p`
  color: #fff;
  font-family: var(--font-family-montserrat);
  font-size: 24px;
  text-align: center;
`
export const CtaBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Cta = ({primary,big,dark}) => {
  return (
    <>
      <CtaContainer id='discover'>
        <CtaBg>
          <VideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
          {/* <CtaImgBg src={Image} position='fixed' object-fit='cover' /> */}
        </CtaBg>
        <CtaContent>
          <CtaTitleH2>
           Discover the taste of home you've been searching for<br /><span>Try Victoria Brands Today</span>
          </CtaTitleH2>
          <CtaMessage>
            For answers to all of your questions or to place an order,<br />please contact one of our sales team members who are standing by to assist you.
          </CtaMessage>
          <HeroBtnWrapper>
            <MailToBtnLink 
              href='mailto:sales@victoriazm.com'
              target='_blank'
              aria-label='Email'
              rel='noopener noreferrer'
              > Contact Sales
            </MailToBtnLink>
          </HeroBtnWrapper>
        </CtaContent>
      </CtaContainer>
    </>
  )
}

export default Cta