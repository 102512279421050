// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react';
import Features from '../components/Features/Features';
import { featureObjOne, featureObjTwo, featureObjThree } from '../components/Features/FeatureInfo';
import HeroLP from '../components/Heros/HeroLP';
import Navbar from '../components/Navbar/NavBar';
import Sidebar from '../components/Navbar/SideBar';
import Footer from '../components/Footer/Footer';
import Cta from '../components/Cta/Cta';
import Products from '../components/Products/Products';



function  Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroLP/>
      <Features {...featureObjOne} />
      <Features {...featureObjTwo} />
      <Features {...featureObjThree} />
      <Products />
      <Cta />
      <Footer />
    </>
  );
}

export default Home