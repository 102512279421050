// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './assets/styles/app.css'
import './assets/styles/theme/variables.css'
import './assets/styles/theme/overrides.css'

/* Pages */
import Home from './Pages/Home'


function App() {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home/>} />
        {/* <Route path='/signin' component={SigninPage} exact /> */}
      </Routes>
    </Router>
  );
}

export default App;