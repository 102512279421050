// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react'
import { Button } from '../Elements.Button'
import { SectionTitleContainer, SectionTitleWrapper, SectionTitleH2, SectionTitleHr } from '../Elements.SecTitle'
import {
  FeatureContainer,
  FeatureWrapper,
  FeatureRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img
 } from './Elements.Features'

const Features = ({
  lightBg,
  imgStart,
  topLine,
  lightText,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  id,
  primary,
  darkText,
  dark,
  dark2
}) => {
  return (
    <>
      {/* <SectionTitleContainer>
        <SectionTitleWrapper>
          <SectionTitleH2>Features</SectionTitleH2>
          <SectionTitleHr />
        </SectionTitleWrapper>
      </SectionTitleContainer> */}
    <FeatureContainer lightBg={lightBg} id={id}>
      <FeatureWrapper>
        <FeatureRow imgStart={imgStart}>
        <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <BtnWrap>
                  {/* <Button
                    to='home'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                  >
                    {buttonLabel}
                  </Button> */}
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} alt={alt} />
              </ImgWrap>
            </Column2>
        </FeatureRow>
      </FeatureWrapper>
    </FeatureContainer>
    </>
  )
}

export default Features