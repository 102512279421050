// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import Icon1 from '../../img/products/grp-1.png'
import Icon2 from '../../img/products/3-bottle-dips.png'
import Icon3 from '../../img/products/ts-1.png'
import Icon4 from '../../img/products/ts-2.png'
import Icon5 from '../../img/products/ts-3.png'
import Icon6 from '../../img/products/packets.png'

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './Elements.Products';

const Products = () => {
  return (
    <ServicesContainer id='products'>
      {/* <ServicesH1>Products</ServicesH1> */}
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Ease of Use</ServicesH2>
          <ServicesP>
            Blah Blah Blah.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Work from Anywhere</ServicesH2>
          <ServicesP>
            Blah Blah Blah.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Reduce Admin Work by 60%</ServicesH2>
          <ServicesP>
            Blah Blah Blah
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2>Reduce Admin Work by 60%</ServicesH2>
          <ServicesP>
            Blah Blah Blah
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon5} />
          <ServicesH2>Reduce Admin Work by 60%</ServicesH2>
          <ServicesP>
            Blah Blah Blah
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon6} />
          <ServicesH2>Reduce Admin Work by 60%</ServicesH2>
          <ServicesP>
            Blah Blah Blah
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Products;
