// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState } from 'react'
import Video from '../../videos/video1.mp4'
import { HeroBtn } from '../Elements.Button'
import { 
  HeroContainer,
  HeroBg,
  HeroVideoBg,
  HeroContent,
  HeroH1,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight
  } from './Elements.Hero'

  function HeroLP() {
    const [hover, setHover] = useState(false);
  
    const onHover = () => {
      setHover(!hover);
    };
  return (
    <>
      <HeroContainer id='home'>
        <HeroBg>
          <HeroVideoBg playsInline autoPlay loop muted src={Video} type='video/mp4' />
        </HeroBg>
        <HeroContent>
          <HeroH1>
            Tastes Like Home
          </HeroH1>
          {/* <HeroBtnWrapper>
            <HeroBtn
                to='signup'
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                primary='true'
                dark='true'
                onMouseEnter={onHover}
                onMouseLeave={onHover}
              >
                Learn More {hover ? <ArrowForward /> : <ArrowRight />}
            </HeroBtn>
          </HeroBtnWrapper> */}
        </HeroContent>
      </HeroContainer>
    </>
  )
}

export default HeroLP