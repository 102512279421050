export const featureObjOne = {
  id: 'about',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Victoria Brands Limited',
  headline: 'About Us',
  description:
    'Victoria Brands Limited is a leading Zambian, manufacturer of sauces, condiments, and other packaged food items. Founded in 2018, and from its factory in Livingstone, Victoria Brands sources the freshest raw materials from commercial farmers and over 60 women-owned out-grower farmers utilizing stringent quality control standards.',
  buttonLabel: 'Learn More',
  imgStart: false,
  img: require('../../img/brand/logo-alpha-lrg.png'),
  alt: 'logo',
  dark: false,
  primary: true,
  darkText: true
};

export const featureObjTwo = {
  id: '',
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Livingstone Zambia',
  headline: 'Factory Operations',
  description:
    'Our factory is in Livingstone Zambia. Ideally located for the export market into Zimbabwe, Botswana, South Africa, Namibia, and Angola. Also well located for receiving imported raw materials, and access to established commercial and out grower farmers in the region.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../img/about/about-1.png'),
  alt: 'Automation',
  dark: true,
  primary: true,
  darkText: true
};

export const featureObjThree = {
  id: '',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Nationwide',
  headline: 'Distribution',
  description:
    "Victoria Brands distributes nationwide through our own logistics in Livingstone and Lusaka, and through a network of wholesalers and distributors.",
  buttonLabel: 'Learn More',
  imgStart: false,
  img: require('../../img/about/about-2.png'),
  alt: 'Communications',
  dark: false,
  primary: false,
  darkText: true
};
